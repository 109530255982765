
import React, { useState, useEffect } from 'react';

import axios from 'axios';

import caledario from "../icons/calendario.png"
import information from "../icons/information.png"
import config from "../icons/Config.png"
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';



const PaginaFolga = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }
  const agora = new Date();
  const dia = agora.getDay();
  const [diaDaSemana, setDiaDaSemana] = useState("");


  const [imagemFunc, setImagemFunc] = useState("");
  const [nomeFunc, setNomeFunc] = useState("");
  const [nomePosto, setNomePosto] = useState("")

  const [idPosto, setIdPosto] = useState();
  const [idFuncPag, setIdFuncPag] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');



  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
        setIdFuncPag(response.data.id)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }


  const API_URL_BUSCA_NUM_GERENTE = "https://api-production-d9e6.up.railway.app/getNumGerente";//IDPOSTO
  const API_URL_BUSCA_FUNC = "https://api-production-d9e6.up.railway.app/NomeFunc"//IFUNC
  const API_URL_BUSCA_POSTO = "https://api-production-d9e6.up.railway.app/buscaPostoPorID"; //IDFUNC
  

  //Numero Gerente
  const [numGerente, setNumGerente] = useState("");

  useEffect(() => {
    const buscaDados = async () => {
      try{
        const modeloImg = await axios.get(`${API_URL_BUSCA_FUNC}/${idFuncPag.toString()}`)
        if(modeloImg.data != 200){
          setImagemFunc(prevItem => modeloImg.data.urlImage);
          setNomeFunc(prevItem => modeloImg.data.Nome);
        }else{
          setImagemFunc(prevItem => "");
          setNomeFunc(prevItem => "");
        }
        
      }catch(error){
        console.log("Erro ao buscar dados! "+ error )
      }
    }
    
    const returnNumero = async () => {
      try{
        const modelo = await axios.get(`${API_URL_BUSCA_NUM_GERENTE}/${idPosto.toString()}`);
        if(modelo.data != 200){
          setNumGerente(prevItem => modelo.data)
        } else {
          setNumGerente(prevItem => "")
        }
      }catch(error){
        console.log("Erro ao buscar número! ")
      }
    }

    returnNumero();
    buscaDados();
    verificaLogin(token)
  }, [idFuncPag])

  useEffect(() => {
    const fetchBuscaPosto = async () => {
      try{
        const modelo = await axios.get(`${API_URL_BUSCA_POSTO}/${idPosto.toString()}`);
        if(modelo.data != 200){

          setNomePosto(prevItem => modelo.data.Nome)
        }

      } catch(error){
        console.error("Erro ao buscar Posto : ", error);
        console.log("Erro ao buscar Posto: ", error)
      }
    }
    fetchBuscaPosto();


  }, [idFuncPag])

  //Navegação
  const minhaFuncao = () => {
    window.location.href = `/calendar?token=${token}`;
    //navigation.navigate('MyCalendarPage', {idFunc: idFuncPag});
  };


  const ajustes = () => {
    window.location.href = `/PaginaConfig?token=${token}`;
    //navigation.navigate('MyCalendarPage', {idFunc: idFuncPag});
  };

  const openWhatsApp = () => {
        
    const message = "Venho por meio deste informar que, devido a questões de saúde, fui atendido por um médico que recomendou meu afastamento das atividades laborais. Em anexo, envio o atestado médico que comprova a necessidade deste afastamento.\n\n*Lembrete: Não esqueça de encaminhar o atestado para a equipe técnica do PontoPosto (+5514996445490)*";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${numGerente}&text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  function retornaDia(num){
    switch (num) {
      case 0:
        return "Domingo";
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terca-Feira";
      case 3: 
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sabado";
      default:
        return "Dia inválido";
    }
  }
  useEffect(()=>{
    const nome = retornaDia(dia);
    setDiaDaSemana(prevItem => nome);
  },[])




  return (
    <div style={styles.PrinView}>
    <div style={styles.header}>
      <img  src={imagemFunc.toString()}  style={styles.logo}/>
        <div style={styles.letreiro}>
          <div style={styles.divNome}>
            <p style={styles.title}>{nomeFunc.toString()}</p>
          </div>
            
            <div>
              <p style={styles.NomePosto}>{nomePosto}</p>
            </div>
            
        </div>

        <div style={styles.parteDireita}>

        </div>
      </div>

      <div style={styles.PaginaToda}>
        <div style={styles.container}>
          <div style={styles.relogio}>
            <div style={styles.containerLetreiro}>
              <h3 style={styles.letreiroDiaSemana} >{diaDaSemana}</h3>
                <h3 style={styles.FolgaText}>FOLGA</h3>


            </div>
          </div>
        </div>

      </div>
        <div style={styles.tabBar}>
              <button style={styles.tabBarButton}>
                <img src={caledario} style={styles.icon} onClick={minhaFuncao}/> 
              </button>
              <button style={styles.tabBarButton}>
                <img src={information} style={styles.icon} onClick={openWhatsApp}/> 
              </button>
              <button style={styles.tabBarButton}>
                <img src={config} style={styles.icon} onClick={ajustes}/>
              </button>
          </div>
    </div>
  )
}

const styles = {
  PrinView: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#f0f0f0',
    borderBottom: '1px solid #ccc',
    borderRadius: 10,
    height: 90,
  },
  logo: {
    width: '90px',
    height: '90px',
    marginRight: 0,
    borderRadius: 100,
    marginTop: 10,
  },
  letreiro: {
    marginLeft: 10,
    marginTop: -10,

  },
  divNome:{
    
  },

  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 20,
    width: 300,
    fontWeight: 'bold',

    
  },
  NomePosto:{
    marginTop: -15
  },
  PaginaToda: {
    height: 445,
    width: 300,
    textAlign: 'center',
    backgroundColor: 'white',
    marginLeft: "15%",
    marginTop: '10%',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
    borderRadius: 16,
    marginTop: 45
  },
  FolgaText: {
    fontSize: 50,
    color: 'green',
    marginTop: 150
  },
  containerLetreiro: {

    height: 125,
  },
  letreiroDiaSemana: {
    fontSize: 25,
    padding: 0
  },
  containerDataHor: {
    display: 'flex',
    marginTop: -29,
    marginLeft: "10%",

  },
  data: {
    marginRight: 40,
  },
  textData: {
    fontSize: 19
  },
  textVarData: {
    fontSize: 26,
    marginTop: -12
  },
  Hor: {

  },
  textVarDataVerd: {
    fontSize: 26,
    marginTop: -12,
    color: 'green'
  },
  textVarDataVerm: {
    fontSize: 26,
    marginTop: -12,
    color: 'red'
  },
  spiral: {

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250
    
  },
  containerBotao: {
    position: 'absolute',
    marginTop: "70%"
  },
  tabBar: {
    display: 'flex',

    height: 70,
    width: 260,
    marginLeft: 75,
    marginTop: 17,
  },
  tabBarButton: {
    margin: 10,
    height: 65,
    width: 75,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
  },
  icon: {
    height: 50,
    width: 50,
    
  }

}


export default PaginaFolga;

import React, { useState, useEffect } from 'react';

import axios from 'axios';
import gif from '../icons/loading.gif'
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';


const PaginaFrentista = () => {

    if(isDesktop){
        window.location.href = `/Desktop`;
      }
    const [isLoading, setIsLoading] = useState(true);


    const API_URL_BUSCA_DIA_TRAB = "https://api-production-d9e6.up.railway.app/buscaDiaTrabalho"//IDFUNC

    const [idPosto, setIdPosto] = useState();
    const [idFuncPag, setIdFuncPag] = useState();
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
  
  
  
    const verificaLogin = async (token) => {
      const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
      try{
        const response = await axios.post(`${API_URL_Verify}`, {
          token: token
        })
        if(response.data != 200){
          setIdPosto(response.data.idPosto)
          setIdFuncPag(response.data.id)
        } else {
          window.location.href = "/";
        }
      }catch(error){
        window.location.href = "/";
      }
    }
  



    useEffect(() => {
        verificaLogin(token)

        const fetchBuscaDia = async () => {
            const agora = new Date();
            const dia = agora.getDay();
            const day = agora.getDate();
            const month = agora.getMonth() + 1;
            const year = agora.getUTCFullYear();
            const dataNow = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;

            try {
                const modelo = await axios.post(`${API_URL_BUSCA_DIA_TRAB}/${idFuncPag.toString()}`, {
                    DataSelecionada: dataNow
                });
                if(modelo.data != 200){
                    const dados = modelo.data
                    if (!dados.Folga == true) {
 
                        navigateToNextScreen(1,token);
                    } else {
                        navigateToNextScreen(2,token);

                    }
                    setIsLoading(prevItem => false);
                }
                
            } catch (error) {
                console.log("ErroAPI", error);
            }
        };
        const timer = setTimeout(() => {
            setIsLoading(false);
            fetchBuscaDia();
        }, 5000); // 10 segundos

        return () => clearTimeout(timer);
        
    }, [idFuncPag]);

    const navigateToNextScreen = (TipoId, token) => {
        if (TipoId == 1) {
            window.location.href = `/PagFrents?token=${token}`;
        } else if (TipoId == 2) {
            window.location.href = `/PaginaFolga?token=${token}`;
        }
    };

    return (
        <div>
            {isLoading ? (
                <div>
                    <img src={gif} style={style.loading}/>
                </div>
            ):(
                <div>
                </div>
            )}
        </div>
    )}
    const style = {
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '100%',
            maxHeight: '100vh',
            margin: 'auto'
        }

    }


    export default PaginaFrentista;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from "../images/Logo.png"
import emailjs from 'emailjs-com'
import { isDesktop } from 'react-device-detect';




const API_URL_LOGIN = 'https://api-production-d9e6.up.railway.app/Login';
const API_URL_RECUPERA_SENHA = 'https://api-production-d9e6.up.railway.app/RecuperaSenha';


const Home = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  //const navigation = useNavigation();


  const handleForgotPassword = async () => {

    try{
      var email = ""
      var mensg = ""
      const response = await axios.post(`${API_URL_RECUPERA_SENHA}`, {
        Telefone: phoneNumber
      })
      
      if(response.data === 201 ){
        alert("Email já enviado.")
        return;
      } 
      if(response.data === 200){
        alert("Telefone não cadastrado!")
        return;
      }

        email = response.data.email
        mensg = response.data.mensg
        const emailContent = {
          to_email: email,
          subject: "Senha Ponto Posto",
          message: mensg.toString(),
      }
        emailjs.send('service_b04a71g', 'template_pl132wa', emailContent, '2v4xEhf8ZdD0SCgyo')
        .then((response) => {
            alert("Recuperação de senha solicitada via Email!")
      
        })
        .catch((error) => { // Usando o método catch para lidar com o erro
            alert("Erro ao enviar Email! ")
      
        });
      setIsOpen(false);
  
    }catch(error){
      setIsOpen(false);
    }
   



}

    // Lógica para enviar o número de celular para recuperação de senha
    // Aqui você pode implementar a lógica de envio de SMS, por exemplo





  /*
  const retrieveLoginData = async () => {
    try {
      const savedUsername = await AsyncStorage.getItem('email');
      if (savedUsername !== null) {
        setEmail(savedUsername);
      }
      // Aqui você pode optar por recuperar a senha também, mas deve ser feito com segurança
    } catch (error) {
      console.error('Erro ao recuperar dados de login:', error);
    }
  };
  */
  
  useEffect(() => {
    // Verificar se há dados de login salvos ao carregar a tela
    //retrieveLoginData();
  }, []);



  const verificaLogin = async () => {
    try{

      //await AsyncStorage.setItem('email', email);
      const reponse = await axios.post(`${API_URL_LOGIN}`, {
        Telefone : email,
        Senha: password
      })
      if(reponse.data != 200){
        const respostaData = reponse.data
        if(respostaData == false) {
          
          alert('Telefone ou senha incorretos. \nVerifique suas informações e tente novamente!')
        }else {
          setEmail(prevItem => '');
          setPassword(prevItem => '')
          if(respostaData.Tipo == "Gerente"){
            const token = respostaData.token
            //navigation.navigate('PagSemana', { idPosto: idPosto });
            window.location.href = `/Semanas?token=${token}`;

          } if(respostaData.Tipo == "Funcionario") {
            const token = respostaData.token
            window.location.href = `/PaginaFrentista?token=${token}`;
            //navigation.navigate('PaginaFrentista', {idPosto: idPosto, idFuncPag : idFunc});
            //window.location.href = `/home?userId=${encodeURIComponent(userId)}&userName=${encodeURIComponent(userName)}`;

          }
        }
      } else {
        console.error("Erro ao solicitar acesso!")
      }
      
    }catch(error){
      console.log("Erro ao verificar login!")
    }
  }


  return (

  <div style={styles.container}>
    <div style={styles.viewLogo}>
      <img src={Logo} style={styles.logo} />
    </div>

    <div style={styles.body}>
      <div style={styles.Letreiro}>
        <h1 style={styles.title}>Bem-vindo de volta!</h1>
        <p style={styles.subtitle}>Faça login para continuar</p>
      </div>
      

      <input type="email" placeholder="Celular" style={styles.input} onChange={(e) => setEmail(e.target.value)}/>
      <input type="password" placeholder="Senha" style={styles.input} onChange={(e) => setPassword(e.target.value)}/>

      <button style={styles.button} onClick={verificaLogin}>Login</button>
      <div style={styles.divBotao}>
        <button style={styles.botaoEsqueceu} onClick={handleOpen}>Esqueceu a senha?</button>
      </div>
      
    </div>
    {isOpen && (
        <div className="popup-container" style={styles.modalStyle}>
          <div className="popup-content" style={styles.modalContainer}>
            <h2 style={styles.modalTitle}>Recuperação de Senha</h2>
            <p style={styles.modalText}>Informe seu número de celular:</p>
            <p style={styles.modalText}>+55 DDD Número de celular</p>
            <input type="tel" placeholder="" onChange={(e) => setPhoneNumber(e.target.value)} style={styles.modalInput} />
            <div style={styles.divButtonSMS}>

              <button style={styles.botaoEsqueceu} onClick={handleClose}>Cancelar</button>
              <button style={styles.botaoEsqueceu} onClick={handleForgotPassword}>Enviar Email</button>
            </div>
          </div>
        </div>
      )}

  </div>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
    width: "100%"
  },
  viewLogo: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
    width: "100%"
  },
  logo: {
    width: 350,
    height: 350,
    marginLeft: "3%"
  },
  body: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 30,
    textAlign: 'center'
  },
  Letreiro: {
    marginBottom: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#808080',
  },
  input: {
    width: '100%',
    height: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    marginBottom: 20,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', 
  },
  button: {
    width: '108%',
    height: 40,
    backgroundColor: '#e0e0e0',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', 
    fontSize: 15,
    fontWeight: 'bold',
    color: "rgba(248,167,81,1)",
    fontSize: 16,
    fontWeight: 'bold',

  },

  botaoEsqueceu: {
    textAlign: 'center',
    height: 30,
    width: 160,
    border: 'none',
    marginLeft: 25,
    marginTop: -20,
    background: 'none'
  },
  divBotao:{
    textAlign: 'center',
    height: 40,
    marginLeft: 30,
    margin: 5
  },
  modalStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: "65%",
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  modalText: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  divButtonSMS:{
    display: 'flex',
    marginTop: 20,
    marginLeft: -40
  },
  modalInput: {
    width: '28vh',
    height: '20px',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};


export default Home;

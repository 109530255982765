import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Logo from "../../images/Logo.png"
import Flecha from '../icons/Flecha.png'
import { isDesktop } from 'react-device-detect';


const PaginaConfig = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }

  const [modalVisible, setModalVisible] = useState(false);
  const [preferences, setPreferences] = useState([
    { label: 'Erro no aplicativo em geral', checked: false },
    { label: 'Erro no calendario', checked: false },
    { label: 'Mudança de perfil', checked: false },
    { label: 'Contato em geral', checked: false },
    { label: 'Outros', checked: false },

  ]);

  const [isOpen, setIsOpen] = useState(false);

  const [nomeFunc, setNomeFunc] = useState("");
  const [imagemFunc, setImagemFunc] = useState("");

  const API_URL_BUSCA_FUNC = "https://api-production-d9e6.up.railway.app/NomeFunc"//IFUNC
  const API_ENVIAR_SUPORTE = "https://api-production-d9e6.up.railway.app/EnviarSuporte" //IDFUNC

  const [idPosto, setIdPosto] = useState();
  const [idFunc, setIdFuncPag] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');



  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
        setIdFuncPag(response.data.id)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }



  const buscaDados = async () => {
    try{
      const modeloImg = await axios.get(`${API_URL_BUSCA_FUNC}/${idFunc.toString()}`);

      if(modeloImg.data != 200){
        setImagemFunc(prevItem => modeloImg.data.urlImage);
        setNomeFunc(prevItem => modeloImg.data.Nome)
      } else{
        setImagemFunc(prevItem => "");
        setNomeFunc(prevItem => "")
      }
        

      
    }catch(error){
      console.log("Erro ao buscar dados! "+ error )
    }
  }

  useEffect(() => {
    verificaLogin(token)
    buscaDados();
    
  }, [idFunc]);

  const toggleCheckbox = index => {
    const newPreferences = [...preferences];
    newPreferences[index].checked = !newPreferences[index].checked;
    setPreferences(newPreferences);
  };

  
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const close = () => {
    handleClose();
  }

  const enviarSuporte = async () => {
    try{
        const envioSup = await axios.post(`${API_ENVIAR_SUPORTE}/${idFunc.toString()}`, {
            idPosto: idPosto,
            Erro1 : preferences[0].checked,
            Erro2 : preferences[1].checked,
            Erro3 : preferences[2].checked,
            Erro4 : preferences[3].checked,
            Erro5 : preferences[4].checked,
        });
        if(!envioSup.data == 200) {
        } else {
          console.error("Erro ao enviar solicitação Suporte!")
        }
        
    }catch(error){
        console.log("Erro ao enviar Suporte: "+ error)
    }
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    window.location.href = `/`;
  }


  const handleVoltar = () =>{
    window.location.href = `/PaginaFrentista?token=${token}`;
  };


  return (
    
    <div style={styles.container}>
      <button style={styles.botaoVoltar} onClick={handleVoltar}>
        <img src={Flecha} style={styles.IconeSelecionado}/>
      </button>
        <div style={styles.divcenter}>
        <img src={imagemFunc.toString()} style={styles.profileImage} width="120" height="120" />
        <h2 style={styles.userName}>{ nomeFunc }</h2>
        <button style={styles.button} onClick={handleOpen}>Suporte</button>
        <button style={styles.button} onClick={handleLogout}>Sair</button>
        </div>

        {isOpen && (
        <div className="popup-container" style={styles.modalStyle}>
            <div style={styles.modalContainer}>
            {preferences.map((preference, index) => (
                <div key={index}>
                <input
                    type="checkbox"
                    checked={preference.checked}
                    onChange={() => toggleCheckbox(index)}
                />
                <span>{preference.label}</span>
                </div>
      
            ))}
              <button style={styles.botao} onClick={close}>Cancelar</button>
                <button style={styles.botao} onClick={enviarSuporte}>Enviar</button>
            </div>

            
        </div>
      )}


    </div>
  );
};

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    botaoVoltar: {
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      zIndex: 1,
      height: 35,
      width : 35
    },
    divcenter:{
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 170
    },
  profileImage: {
    marginBottom: 19,
    borderRadius: 45
  },
  userName: {
    fontSize: 25,
    marginBottom: 16,
  },
  button: {
    width: '60%',
    height: 50,
    backgroundColor: '#e0e0e0',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)', 
    marginTop: 10
  },
  modalStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },
  botao: {
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 13
  },

  tabNavigation: {
    width: "100%",
    height: 60,
    border: "1px solid #ccc",
    marginTop: 165,
    padding: 10,

  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    width: 300,
    marginLeft: 70
  
  },
  tabsActive: {
    padding: 10,
    borderBottom: "2px solid #ccc",
    cursor: "pointer",
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    color: 'green',
  },
  IconeSelecionado:{
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    height: 35,
    width: 35,
    marginTop: 0,
    zIndex: 1,
    position: 'absolute',
    marginLeft: -15
  },
  tab: {
    padding: 10,
    borderBottom: "2px solid #ccc",
    cursor: "pointer",
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
     },
     Icone: {
        height: 30,
        width: 30
      },

};


export default PaginaConfig;

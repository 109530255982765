import React, { useState, useEffect } from 'react';
import Flecha from '../icons/Flecha.png'
import axios from 'axios';
import Logo from "../../images/Logo.png"
import { isDesktop } from 'react-device-detect';


const PagAddFuncionario = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }

  function showAlertCancel(string, mensagem) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <button id="cancel-btn">Cancelar</button>
    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.zIndex = "1000";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
    alertBox.style.textAlign ='center';
  
    document.body.appendChild(alertBox);


    var cancelBtn = document.getElementById("cancel-btn");
  
  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }


    const API_URL_ADD = 'https://api-production-d9e6.up.railway.app/adicionaFuncinario';
    const API_URL_SOLICITACAO =
  'https://api-production-d9e6.up.railway.app/AdicionaSolicitacaoAWS';

  const [idPosto, setIdPosto] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');



  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }

  useEffect(() => {
    verificaLogin(token);
  }, [idPosto]);


    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [imageUri, setImageUri] = useState(null);
  
    const [isOpen, setIsOpen] = useState(false);
    const [facing, setFaceing] = useState('back');
  
    const [isModalVisible, setModalVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
  
    const [mostraCamera, setMostraCamera] = useState(false);
  
   
  
    const [isButtonPressed, setIsButtonPressed] = useState(false);
  
  
  /*
    useEffect(() => {
      const loadHtmlContent = async () => {
        try {
          // Carregar o arquivo HTML dos ativos
          const asset = Asset.fromModule(require('./termosecondicoes.html'));
          await asset.downloadAsync();
          const fileUri = asset.localUri;
  
          // Ler o conteúdo do arquivo HTML
          const html = await FileSystem.readAsStringAsync(fileUri);
  
          setHtmlContent(prevItem => html);
        } catch (error) {
          console.error('Error loading HTML:', error);
        } finally {
          setLoading(false);
        }
      };
  
      loadHtmlContent();
    }, []);
    */
  

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
  
    const handleCheckBoxChange = () => {
      setIsChecked(!isChecked);
    };
  
  
  
  
  
    const addFunc = async () => {

  
      try {
        if (!isChecked) {
          showAlertCancel("Termos e Serviços", 'Para continuar, você precisa aceitar os Termos e Serviços. Por favor, leia e aceite para prosseguir com o cadastro.')

          return;
        }
        if (!name || !phone || !email || !password) {
          showAlertCancel("Campos Obrigatórios", 'Para continuar, todos os campos obrigatórios do cadastro precisam ser preenchidos. Por favor, revise suas informações e tente novamente.')

          return;
        }
  
        await axios.post(API_URL_ADD, {
          Nome: name,
          horasTotais: 0,
          DiasDisponiveis: 6,
          FolgasDisponiveis: 0,
          NumDeSemanasTrabalhadas: 0,
          IdPost: idPosto,
          Email: email,
          Telefone: phone,
          Senha: password,
          urlImage: "https://img.freepik.com/vetores-premium/icone-de-perfil-de-usuario-em-estilo-plano-ilustracao-em-vetor-avatar-membro-em-fundo-isolado-conceito-de-negocio-de-sinal-de-permissao-humana_157943-15752.jpg",
        });
  
        await axios.post(API_URL_SOLICITACAO, {
          Nome: name,
          Email: email,
          Telefone: phone,
          idPosto: idPosto,
        });
        alert('Funcionário Adicionado!')
        window.location.href = `/PagFunc?token=${token}`;

      } catch (error) {
        alert('Erro ao adicionar funcionário!')

      } 
    };
    const handleVoltar = () =>{
      window.location.href = `/PagFunc?token=${token}`;
    };

    return (
      <div style={styles.Container1}>
        <div style={styles.container}>
              <button style={styles.botaoVoltar} onClick={handleVoltar}>
                <img src={Flecha} style={styles.IconeSelecionado}/>
              </button>
            <h1 style={styles.title}>Registrar Funcionário</h1>
            <div style={styles.inputs}>

            <input style={styles.input} type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} />

            <input style={styles.input} type="tel" placeholder="Telefone" onChange={(e) => setPhone(e.target.value)} />

            <input style={styles.input} type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />

            <input style={styles.input} type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />

                <button style={styles.buttonTermos} onClick={() => toggleModal()}>Termos e Condições</button>
            </div>

            {isOpen && (
            <div>
                <div style={styles.modalStyle} id="modal">
                    <div style={styles.modalContainer}>
                        <div style={styles.checkBoxContainer}>
                                <h1 >Termos e Condições do PontoPosto</h1>
                                <p >Estes Termos regem</p>
                                <p  />
                                <p >o uso deste serviço (este Aplicativo), e,</p>
                                <p >
                                    qualquer outro Contrato relacionado ou relação legal com o Proprietário
                                </p>
                                <p >de maneira legalmente vinculante. Palavras iniciando em maiúsculas são definidas na respectiva seção específica deste documento.</p>
                                <p ><br /></p>
                                <p >O Usuário deverá ler este documento com atenção.</p>
                                <p >
                                    Embora toda a relação contratual relacionada a estes Produtos seja celebrada somente pelo Proprietário e Usuários, os Usuários reconhecem e concordam que, onde este Aplicativo tiver sido fornecido a eles,
                                    poderá exigir o cumprimento destes Termos como um terceiro beneficiário.
                                </p>
                                <div>
                                <button style={styles.button1}>Documento Completo</button>
                                </div>
                        <input type="checkbox" id="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                            <label for="checkbox" style={styles.checkBoxText}>Eu concordo com os Termos e Condições</label>
                        </div>
                        <button style={styles.buttonFechar} onClick={() => toggleModal()}>Fechar</button>
                    </div>
                </div>
                
            </div>
            )}    
            <button style={styles.button} onClick={() => addFunc()}>Registrar</button>
        </div>
        </div>
    )}

    const styles = {

        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            backgroundColor: '#f5f5f5',
          },
          botaoVoltar: {
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: 'pointer',
            zIndex: 1,
            height: 35,
            width : 35
          },
          IconeSelecionado:{
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: 'pointer',
            height: 35,
            width: 35,
            marginTop: 35,
            zIndex: -1,
            position: 'absolute',
            marginLeft: -190
          },
          title: {
            marginBottom: 45,
            marginLeft: 19,
            marginTop: 12
          },
          inputs:{
  
            textAlign: 'center',
            width: 500,
            height: 400,

            marginTop: 65
          },

          input: {
            width: '65%',
            height: 20,
            padding: 10,
            borderWidth: 1,
            borderColor: '#ccc',
            borderRadius: 5,
            marginBottom: 20,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
          },
          buttonTermos: {
            width: '45%',
            height: 35,
            backgroundColor: 'white',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
            fontSize: 15,
            fontWeight: 'bold',
            borderRadius: 15,
            marginTop: 10,

          },
          buttonFechar: {
            width: '45%',
            height: 35,
            backgroundColor: 'white',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
            fontSize: 15,
            fontWeight: 'bold',
            borderRadius: 15,
            marginTop: 10,
            marginLeft: 85
          },
          modalStyle: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          modalContainer: {
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          },
          button1: {
            background: 'none',
            marginLeft: "30%",
            marginBottom: "4%"
          },
          button: {
            width: '45%',
            height: 35,
            backgroundColor: 'white',
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',

            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
            fontSize: 15,
            fontWeight: 'bold',
            marginTop: 100


        
          },
    }

    export default PagAddFuncionario;
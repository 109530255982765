import React, { useState, useEffect } from 'react';

//import Icon from 'react-native-vector-icons/FontAwesome';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import Calendar from "../icons/Calendar.png"
import Clock from "../icons/Clock.png"
import Seta from "../icons/Seta.png"

import Config from "../icons/Config.png"
import Funcs from "../icons/Funcs.png"
import SemanasIcon from "../icons/Semanas.png"
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';

//import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

//{ idPosto }
const API_URL_SEMANAS = 'https://api-production-d9e6.up.railway.app/semanas';




const Semanas = () => {

  if(isDesktop){
    window.location.href = `/Desktop`;
  }
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const PaginaFuncionarios = () => {
    window.location.href = `/PagFunc?token=${token}`;
  }

  const PaginaGerente = () => {
    
    window.location.href = `/PagConfigGerente?token=${token}`;
  }
 


  const [idPosto, setIdPosto] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');



  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }


  const [semanas, setSemanas] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const fetchSemanas = async () => {

    try {
      const response = await axios.get(`${API_URL_SEMANAS}/${idPosto}`);
      if(response.data != 200){
        const sortedSemanas = response.data.sort((a, b) => b.id - a.id);
        setSemanas(prevItem => sortedSemanas);
  
      } else {
        setSemanas(prevItem => [])
      }
      
    } catch (error) {
      console.error('Erro na requisição à API:', error);
    }
  };

  useEffect(() => {
    verificaLogin(token);
    fetchSemanas();

  }, [idPosto,semanas]);

  const onRefresh = () => {
    setRefreshing(true);
    fetchSemanas().then(() => setRefreshing(false));
  };

  const addSemana = () => {
    window.location.href = `/AddSemana?token=${token}`;
  }

  const navegarParaProximaTela = (id) => {
    //navigation.navigate('Segunda', { semanaId: id, idPosto });
    window.location.href = `/PaginaQuad1?token=${token}&semanaId=${id}`;//Precisa do id da Semana tambem
  };

  const navegarAddSem = () => {
    //navigation.navigate('PagAddSemana', { idPosto });
  };
  /*
  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={() => navegarParaProximaTela(item.id)}>
      <View style={styles.itemSemanas}>
        <Text>{item.Nome}</Text>
        <Text>{item.Dia1}</Text>
        <Text>{item.Dia7}</Text>
        {item.Finalizada ? (
          <Icon name="check" size={20} color="green"  />
        ) : item.Preenchida ? (
          <Icon name="clock-o" size={20} color="green" />
        ) : (
          <Icon name="calendar" size={20} color="green" />
        )}
        <Icon name="chevron-right" size={20} color="green" />
      </View>
    </TouchableOpacity>
  );
  */

    return (
      <div style={styles.container}>
        <div style={styles.containerLetreiro}>
          <h1 style={styles.letreiroSemana}>Semanas Registradas</h1>
          <button style={styles.iconePlusContainer} onClick={() => addSemana()}>+</button>
        </div>
    
        <div style={styles.containerSemanas}>
          <div style={styles.containerTitulo}>
            <text style={styles.tituloNome}>Nome</text>
            <text style={styles.tituloInicio}>Inicio</text>
            <text style={styles.tituloFim}>Fim</text>
          </div>
            <div style={styles.divSemanaRolagem}>
              {semanas.map((item) => (
                <div style={styles.divSemana}>
                  <div style={styles.itemSemanas}>

                    <h4 style={styles.NomeSem}>{item.Nome}</h4>
                    <h4 style={styles.DiasSem}>{item.Dia1}</h4>
                    <h4 style={styles.DiasSem}>{item.Dia7}</h4>
                    <div style={styles.Botoes}>
                      {item.Finalizada ? (
                        <img src={Calendar} style={styles.Calendar} />
                      ) : item.Preenchida ? (
                        <img src={Clock} style={styles.Clock} />
                      ) : (
                        <img src={Calendar} style={styles.Calendar} />
                      )}
                      
                    </div>
                    <button style={styles.botaoSeta} onClick={() => navegarParaProximaTela(item.id)}>
                      <img src={Seta} style={styles.Seta}/>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={styles.tabNavigation}>
          <div style={styles.tabs}>
            <button style={styles.tabsActive} >
              <img src={SemanasIcon} style={styles.IconeSelecionado}/>
            </button>
            <button style={styles.tab} onClick={PaginaFuncionarios} >
              <img src={Funcs} style={styles.Icone}/>
            </button>
            <button style={styles.tab}>
              <img src={Config} style={styles.Icone} onClick={PaginaGerente}/>
            </button>
          </div>
        </div>

      </div>
    );
  }

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#f5f5f5',
    },
    
    containerLetreiro: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '90%',
      marginTop: '50px',
      justifyContent: 'center',
      marginBottom: 20,
    },
    letreiroSemana: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginRight: 30,
      paddingLeft: 6,
    },
    iconePlusContainer: {
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '50%',
      fontSize: '20px',
      border: 'none',
      cursor: 'pointer',
    },
    containerSemanas: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50vh',
      height: "100vh",
      marginTop: '20px',

    },
    containerTitulo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 240,
      justifyContent: 'space-between',
      marginBottom: '-10px',
      marginRight: 75,
    },
    tituloNome: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#333',
    },
    tituloInicio: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#333',
      marginLeft: -65
    },
    tituloFim: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#333',
      marginLeft: -90
    },
    divSemanaRolagem: {
      width: 320,
      height: 430,
      overflowY: 'auto',

    },
    divSemana: {
      height: 100,
      width: 300,
      display: 'inline',
      flexDirection: 'row',
    },
    itemSemanas: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      padding: '10px 0',
      borderBottom: '1px solid #ccc',
    },
    NomeSem: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 13,
      width: 85
    },
    DiasSem: {
      fontSize: 13,
      width: 50,
      fontWeight: "normal",
      marginRight: 5
    },
    Botoes: {
      width: 3
    },
    Calendar: {
      height: 23,
      width: 23
    },
    Clock: {
      height: 40,
      width: 40,
      marginLeft: -10,
    },
    botaoSeta: {
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer'
    },
    Seta: {
      height: 18,
      width: 18,
    },
    tabNavigation: {
      width: "100%",
      height: 60,
      border: "1px solid #ccc",
      padding: 10,

    },
    tabs: {
      display: "flex",
      justifyContent: "space-between",
      margin: 10,
      width: 300,
      marginLeft: 70
    
    },
    tabsActive: {
      padding: 10,
      borderBottom: "2px solid #ccc",
      cursor: "pointer",
      background: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      color: 'green',
    },
    IconeSelecionado: {
      height: 30,
      width: 30,
      
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',

      
    },
    Icone: {
      height: 30,
      width: 30
    },
    tab: {
      padding: 10,
      borderBottom: "2px solid #ccc",
      cursor: "pointer",
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
       }
  };
export default Semanas
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import wwpIcon from '../icons/wwpIcon.png'
import Config from "../icons/Config.png"
import Funcs from "../icons/Funcs.png"
import SemanasIcon from "../icons/Semanas.png"
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';
import moment from 'moment';



const API_URL_LISTA_FUNC = 'https://api-production-d9e6.up.railway.app/funcionarios';
const API_URL_DIAS_TRAB = "https://api-production-d9e6.up.railway.app/StatusDiaTrab";


//{ idPosto }
const PagFuncionarios = () => {



  if(isDesktop){
    window.location.href = `/Desktop`;
  }

  const [idPosto, setIdPosto] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  

  const agora = moment(); // obter o horário atual
  const horarioAgr = agora.add(15, 'minutes').format('HH:mm');
  const horarioAgr1 = agora.format('HH:mm');

  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }
  



    const openWhatsApp = (phoneNumber, message) => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(whatsappUrl, '_blank');
    };
  
  const PaginaGerente = () => {
  
    window.location.href = `/PagConfigGerente?token=${token}`;
  }

  const PaginaSemana = () => {
  
    window.location.href = `/Semanas?token=${token}`;
  }

  const paginaAddFunc = () => {
    //navigation.navigate('PagAddFuncionario', {idPosto: idPosto});
    window.location.href = `/PagAddFunc?token=${token}`;
  }

  const [trabalhadores, setTrabalhadores] = useState([]);
  const [diaTrab, setDiaTrab] = useState([])
  const [result, setResult] = useState([])

  useEffect(() => {
    verificaLogin(token);
    
  }, [idPosto]);

  useEffect(() => {
    const listaDiasTrab = async () => {
      try{
        const data = new Date();
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        
        const Data = `${dia}/${mes}/${ano}`;
        const response = await axios.post(`${API_URL_DIAS_TRAB}`, {
          Data: Data,
          idPosto: idPosto
        })

         if(response.data != 200){
          setTrabalhadores(prevItem => response.data);
        }else {
          setTrabalhadores(prevItem => []);
        }
      }catch(error){
        console.log("Erroa ao buscar status!")
      }
      
    }

    
    listaDiasTrab()
    
  },[trabalhadores, idPosto])

  const buscaFalta = async (idFunc1) => {
    const data = new Date();
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    
    const Data = `${dia}/${mes}/${ano}`;
    const API_URL_BUSCA_FALTA = 'https://api-production-d9e6.up.railway.app/BuscaFaltaDia'
    try{
      const response = await axios.post(`${API_URL_BUSCA_FALTA}/${idFunc1.toString()}`, {
        Data : Data
      })

        if(response.data != false){
          return false;
        }else {
          return true;
        }
    }catch(error){
      return false;
    }
  }




  
  /*
  useEffect(() => {
    const fetchTrabalhadores = async () =>{
      try{
        const response = await axios.get(`${API_URL_LISTA_FUNC}/${idPosto.toString()}`);
        if(response.data != 200){
          setTrabalhadores(prevItem => response.data);
        }else {
          setTrabalhadores(prevItem => []);
        }
        
      }catch(error){
        console.error("Erro ao buscar trabalhadores: ", error)
      }
    };
    fetchTrabalhadores();
  }, [idPosto])

  */





  return (
    <div style={styles.container}>
        <h1 style={styles.funcRegistrado}>Funcionários Registrados</h1>
        <div style={styles.Lista}>
            {trabalhadores.map((item) => (
                <div style={styles.containerItem}>
                  <div style={styles.conteudoEsquerda}>
                    
                        <img src={item.urlImage.toString()}  style={styles.imagem}/>
                        <div style={styles.textoContainer}>
                            <h3 style={styles.Nome}>{item.Nome}</h3>
                            <h3 style={styles.Telefone}>{item.Telefone}</h3>
                            <div style={styles.statusDiv}>

                              
                            { item.trabalhando ? (
                              <p style={styles.status}>Trabalhando</p>
                            ):item.almocando ? (
                              <p style={styles.status}>Almoçando</p>
                            ): item.atrasado ?(
                              <p style={styles.statusRed}>Atrasado</p>
                            ):item.horaEfetivaDeEntrada == undefined ?(
                              <p>{item.horaDeEntrada}</p>
                            ): item.TrabalhoDiaConcluido ?(
                              <p style={styles.status}>Dia Concluido</p>
                            ):(
                              <p></p>
                            )}
                            </div>
                        </div>
                        
                  <div>
                      
                      <button style={styles.whatsapp} onClick={() => openWhatsApp(item.Telefone.toString(), '')}>
                        <img src={wwpIcon} style={styles.Iconewwp}/>
                      </button>
                  </div>
                    
                  </div>
                </div>
                
              ))}
              
        </div>
        <div style={styles.divBotao}>
            <button style={styles.botaoAdd} onClick={paginaAddFunc} >+</button>
        </div>
        

        <div style={styles.tabNavigation}>
          <div style={styles.tabs}>
            <button style={styles.tabsActive} onClick={PaginaSemana} >
              <img src={SemanasIcon} style={styles.Icone}/>
            </button>
            <button style={styles.tabsActive}  >
              <img src={Funcs} style={styles.IconeSelecionado}/>
            </button>
            <button style={styles.tab} onClick={PaginaGerente}>
              <img src={Config} style={styles.Icone}/>
            </button>
          </div>
        </div>
    </div>
  )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      },
      funcRegistrado: {
        fontSize: 26,
        marginLeft: -40,
        marginBottom: 45
      },
      Lista: {

        height: "100vh",
        overflowY: 'auto',
        marginBottom: 45,
      },
      containerItem: {
        backgroundColor: '#fff',
        padding: 15,
        marginBottom: 10,
        borderRadius: 8,
        height: 70,
        width:'90%',
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.3)', 
      },
      conteudoEsquerda: {
        display: 'flex',

      },
   
      imagem: {
        height: 65,
        width: 65,
        margin: 10
      },
      textoContainer: {
        marginLeft: 0,
        marginRight: 140,
        marginTop: -15,
        width: 80
      },
      Nome: {
        fontSize: 18,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 170

      },
      Telefone: {
        fontSize: 13,
        fontWeight: "normal",
        marginTop: -10
      },
      conteudoDireita:{
        display: 'flex',
        flexWrap: 'wrap'
      },
      statusDiv:{
        marginTop: -10,

        color: 'green',
        fontWeight: 'bold'

      },
      status:{
        fontSize: 16,


      },
      statusRed:{
        color: 'red'
      },
      whatsapp: {
        height: 50,
        width: 40,
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        marginLeft: 4
      },
      Iconewwp: {
        height: 45,
        width: 45,
        margin: 10,
        marginLeft: -4
      },
      divBotao: {
        height: 50,
        width: 50,
        marginLeft: 330,
        marginBottom: -50,
        marginTop: 10,
        zIndex: 1,
        position: 'absolute'
      },
      botaoAdd: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 15px',
        borderRadius: '50%',
        fontSize: '20px',
        border: 'none',
        cursor: 'pointer',
        height: 45,
        width: 45,
      },
      tabNavigation: {
        width: "100%",
        height: 60,
        border: "1px solid #ccc",

        padding: 10,
  
      },
      tabs: {
        display: "flex",
        justifyContent: "space-between",
        margin: 10,
        width: 300,
        marginLeft: 70
      
      },
      tabsActive: {
        padding: 10,
        borderBottom: "2px solid #ccc",
        cursor: "pointer",
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'green',
      },
      IconeSelecionado: {
        height: 30,
        width: 30,

        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
  
        
      },
      Icone: {
        height: 30,
        width: 30
      },
      tab: {
        padding: 10,
        borderBottom: "2px solid #ccc",
        cursor: "pointer",
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
         },
      
};

export default PagFuncionarios;
import React from 'react';
import { ReactSVG } from 'react-svg';


const SpiralProgressBar = ({ startTime, endTime, width, height, isRunning }) => {
  const radius = Math.min(width, height) / 2;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;

  const startTimestamp = startTime.getTime();
  const endTimestamp = endTime.getTime();
  const currentTimestamp = Date.now();

  const rawProgress = ((currentTimestamp - startTimestamp) / (endTimestamp - startTimestamp)) * 100;
  const progress = isRunning ? Math.min(rawProgress, 100) : 0;

  const progressOffsetAngle = (progress / 100) * 360;

  const endPointX = radius + (radius - strokeWidth / 2) * Math.sin((progressOffsetAngle * Math.PI) / 180);
  const endPointY = radius - (radius - strokeWidth / 2) * Math.cos((progressOffsetAngle * Math.PI) / 180);

  const currentTime = new Date(currentTimestamp).toLocaleTimeString();

  return (
    <div style={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <svg width={width} height={height}>
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius - strokeWidth / 2}
          stroke="lightgray"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <path
          d={`M ${width / 2},${strokeWidth / 2}
              A ${radius - strokeWidth / 2},${radius - strokeWidth / 2} 0 ${
            progress > 50 ? 1 : 0
          },1 ${endPointX},${endPointY}`}
          stroke="blue"
          strokeWidth={strokeWidth}
          fill="none"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={0}
        />
        <text
          textAnchor="middle"
          fontWeight="bold"
          fill="black"
          x={width / 2}
          y={height / 2}
          fontSize={25}
        >
          {currentTime}
        </text>
      </svg>
    </div>
  );
};

export default SpiralProgressBar;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Semanas from './pages/Paginas/PagSemana';
import PagFuncionarios from './pages/Paginas/PagFuncionarios';
import PagAddFuncionario from './pages/Paginas/PagAddFuncionario';
import PagAddSemana from './pages/Paginas/PagAddSemana';
import PaginaConfigGerent from './pages/PaginaLogin/PaginaConfigGerent';
import PaginaConfig from './pages/PaginaLogin/PaginaConfig';

import PaginaComQuadrados from './pages/PaginasCalendario/tela3Nova';
import PaginaComQuadrados1 from './pages/PaginasCalendario/tela3';
import PaginaComQuadrados2 from './pages/PaginasCalendario/tela3_3';
import PaginaComQuadrados3 from './pages/PaginasCalendario/tela3_4';
import PaginaComQuadrados4 from './pages/PaginasCalendario/tela3_5';
import PaginaComQuadrados5 from './pages/PaginasCalendario/tela3_6';
import PaginaComQuadrados6 from './pages/PaginasCalendario/tela3_7';
import CustomPage from './pages/PagFrenti/TesteRel';

import MyCalendarPage from './pages/PagFrenti/CalendarPage';
import PaginaFrentista from './pages/PagFrenti/PaginaFrentista';
import PaginaFolga from './pages/PagFrenti/PaginaFolga';

import Desktop from './pages/PaginaDesktop';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/PagFunc' element={<PagFuncionarios/>}/>
        <Route path='/PagAddFunc' element={<PagAddFuncionario/>}/>
        <Route path="/Semanas" element={<Semanas/>}/>
        <Route path="/AddSemana" element={<PagAddSemana/>}/>
        <Route path='/PagConfigGerente' element={<PaginaConfigGerent/>}/>
        <Route path='/PaginaQuad1' element={<PaginaComQuadrados/>} />
        <Route path='/PaginaQuad2' element={<PaginaComQuadrados1/>} />
        <Route path='/PaginaQuad3' element={<PaginaComQuadrados2/>} />
        <Route path='/PaginaQuad4' element={<PaginaComQuadrados3/>} />
        <Route path='/PaginaQuad5' element={<PaginaComQuadrados4/>} />
        <Route path='/PaginaQuad6' element={<PaginaComQuadrados5/>} />
        <Route path='/PaginaQuad7' element={<PaginaComQuadrados6/>} />
        <Route path='/PagFrents' element={<CustomPage/>}/>
        <Route path="/calendar" element={<MyCalendarPage/>}/>
        <Route path="/PaginaConfig" element={<PaginaConfig/>}/>
        <Route path="/PaginaFrentista" element={<PaginaFrentista/>}/>
        <Route path="/PaginaFolga" element={<PaginaFolga/>} />
        <Route path='/Desktop' element={<Desktop/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
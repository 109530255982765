import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from "../images/Logo.png"
import { useGeolocated } from 'react-geolocated';






const Desktop = () => {
      /*
      const [timeoutEsgotado, setTimeoutEsgotado] = useState(false);
      const { coords, isGeolocationAvailable, isGeolocationEnabled } =
      useGeolocated({
          positionOptions: {
              enableHighAccuracy: false,
          },
          userDecisionTimeout: 15000,


      });

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!isGeolocationEnabled) {
            setTimeoutEsgotado(true);
          }
        }, 15000);
      
        return () => clearTimeout(timer);
      }, [isGeolocationEnabled]);

      if (timeoutEsgotado) {
        alert("Tempo Esgotado")
      }


  if(!coords){
    return <div>Obtendo Loc</div>
  }

  const latitude = coords.latitude.toFixed(14);
  const longitude = coords.longitude.toFixed(14);
  */


  return (

  <div style={styles.container}>
    <img src={Logo} style={styles.logo}/>
    
    <h1>Pagina disponível apenas em dispositivos móveis!</h1>
  </div>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: "100%",
    width: "100%"
  },
  logo:{
    height: 550,
    width: 550
  }
};


export default Desktop;

import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import Flecha from '../icons/Flecha.png'
import Logo from "../../images/Logo.png"

import { isDesktop } from 'react-device-detect';


const API_URL_ADD = 'https://api-production-d9e6.up.railway.app/adicionaSemana';
const API_URL_VERIFICA_DISP = "https://api-production-d9e6.up.railway.app/buscaTotalDias"

const PagAddSemana = () => {


  if(isDesktop){
    window.location.href = `/Desktop`;
  }

  function showAlertCancel(string, mensagem) {
    var alertBox = document.createElement("div");
    alertBox.innerHTML = `
      <h2>${string}</h2>
      <p>${mensagem}</p>
      <button id="cancel-btn">Cancelar</button>
    `;
    alertBox.style.position = "fixed";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.transform = "translate(-50%, -50%)";
    alertBox.style.backgroundColor = "white";
    alertBox.style.padding = "20px";
    alertBox.style.border = "1px solid #ccc";
    alertBox.style.borderRadius = "10px";
    alertBox.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.2)";
    alertBox.style.zIndex = "1000";
    alertBox.style.top = "50%";
    alertBox.style.left = "50%";
    alertBox.style.margin = 'auto';
    alertBox.style.textAlign ='center';
  
    document.body.appendChild(alertBox);


    var cancelBtn = document.getElementById("cancel-btn");
  
  
    cancelBtn.addEventListener("click", function() {
      document.body.removeChild(alertBox);
    });
  }

  const [idPosto, setIdPosto] = useState();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');


  const verificaLogin = async (token) => {
    const API_URL_Verify = 'https://api-production-d9e6.up.railway.app/VerifyToken';
    try{
      const response = await axios.post(`${API_URL_Verify}`, {
        token: token
      })
      if(response.data != 200){
        setIdPosto(response.data.idPosto)
      } else {
        window.location.href = "/";
      }
    }catch(error){
      window.location.href = "/";
    }
  }

  const [nome, setNome] = useState('');
  const [segunda, setSegunda] = useState(new Date());
  const [nomeDia, setNomeDia] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [date, setDate] = useState(null);

  const handleDateChange = (date) => {

    handleDateChange1(date.toLocaleDateString())
  };



  useEffect(() => {
    verificaLogin(token);
  }, [idPosto]);

  
  useEffect(() => {
    if(selectedDate){
      const nomedia = obterNomeDoDia(selectedDate);
      setNomeDia(nomedia)
      setSegunda(selectedDate)
    }
    
  },[selectedDate])

  function handleDateChange1(dateString) {
    var parts = dateString.split('/'); // divide a string em partes
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10) - 1; // ajusta o mês para o formato JavaScript (0-11)
    var year = parseInt(parts[2], 10);
  
    var date = new Date(year, month, day); // cria um objeto Date manualmente
  
    var formattedDate = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear();

    setSelectedDate(formattedDate);
  }
  



   function obterNomeDoDia(data) {
    // Dividir a string da data para extrair dia, mês e ano
    const [dia, mes, ano] =  data.split('/').map(Number);
    
    // Criar um objeto Date a partir dos valores extraídos
    const dataObj = new Date(ano, mes - 1, dia); // Mês em JavaScript é 0-11, então subtraímos 1 do mês
    
    // Array com os nomes dos dias da semana
    const diasDaSemana = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado'
    ];
    
    // Obter o índice do dia da semana (0-6) usando getDay()
    const indiceDoDia = dataObj.getDay();
    
    // Retornar o nome do dia da semana correspondente
    return diasDaSemana[indiceDoDia];
}



  const salvar = async () =>{
      if(!nome){
        showAlertCancel("Nome Semana", "Adicione um nome da semna para continuar!")
        return;
      }
      try{
        const totalDiasresponse = await axios.get(`${API_URL_VERIFICA_DISP}/${idPosto}`)
          if(totalDiasresponse.data != 200){
            const totalDias = totalDiasresponse.data;
            if(totalDias.includes(segunda)){

              showAlertCancel("Dia cadastrado",'O dia fornecido já está cadastrado.')
            } else {
              const response = await axios.post(`${API_URL_ADD}`, {
                Nome: nome,
                Dia1: segunda,
                Finalizada: false,
                Preenchida: false,
                idPosto: idPosto
              })
            }
   
        }
        window.location.href = `/Semanas?token=${token}`;
      }catch(error){
        console.error('Erro ao enviar a requisição:', error);
        alert('Houve um erro ao enviar a requisição.');
      }

  }

  const handleVoltar = () =>{
    window.location.href = `/Semanas?token=${token}`;
  };

  return (

    <div style={styles.container}>
    <div style={styles.containerTitle}>
        <button style={styles.botaoVoltar} onClick={handleVoltar} >
        <img src={Flecha} style={styles.IconeSelecionado}/>
        </button>
        <h1 style={styles.title}>Cadastro de Nova Semana</h1>
    </div>
    <div style={styles.meioDaTela}>
        <div style={styles.containerInput}>
        <div style={styles.conteinerInputNome}>
            <label style={styles.inputNome}>Nome:</label>
            <input placeholder="Nome da Semana" style={styles.input} onChange={(e) => setNome(e.target.value)}/>


            <div style={styles.containerCalendario}>
                <div style={styles.calendarContainer}>
                    <Calendar
                    onChange={handleDateChange}
                    value={date}
                    className="calendar"
                    />
                </div>
            </div>
            <div style={styles.centralizaSemanatext}>
            <label style={styles.dateLabel}>Semana começa em:</label>
            <div style={styles.semanaString}>
              <p>{selectedDate}-</p><p> {nomeDia}</p>
            </div>
            </div>
            
        </div>
        </div>
    </div>

        <button style={styles.botaoSalvar} onClick={() => salvar()}>Salvar</button>
    </div>

)}
  


const styles = {  

      container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: '#f5f5f5',
    },
    botaoVoltar: {
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      zIndex: 1,
      height: 35,
      width : 35
    },
    IconeSelecionado:{
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      height: 35,
      width: 35,
      marginTop: 20,
      zIndex: 1,
      position: 'absolute',
      marginLeft: -25
    },

    title:{
      height: 50,
      width: 350,
      fontSize: 25,
      marginLeft: 45,
      marginTop: 5
    },
    inputNome:{
      fontSize: 20,
      fontWeight: "bold"
    },
    input: {
      width: '93%',
      height: 20,
      padding: 10,
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 5,
      marginBottom: 20,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
    },
    containerCalendario:{
      marginBottom: 30
    },
    calendarContainer: {
    width: 300,
    height: 170,
    border: '1px solid #ccc',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  semanaString: {
    display: "flex",
    marginLeft: 70
  },
  centralizaSemanatext: {
    textAlign: 'center',
    alignItems: 'center',
  },
  dateLabel: {
    fontWeight: 'bold'
  },
  calendarHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  calendarBody: {
    padding: 10,
  },
  day: {
    width: 30,
    height: 30,
    fontSize: 14,
    textAlign: 'center',
    padding: 5,
    cursor: 'pointer',
  },
  daySelected: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  dayHover: {
    backgroundColor: '#f0f0f0',
  },
  botaoSalvar: {
    width: '50%',
    height: 35,
    backgroundColor: '',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 180

  },
};
export default PagAddSemana;